
<template>
    <div>
        <div class="listAll">
            <el-image src="http://222.168.10.38:8077/cczcimage/20231016/7209273a57c942828e58407cd805235a.png" class="list_top_img"></el-image>
            <el-image src="http://222.168.10.38:8077/cczcimage/20231012/90935cdbed384074a786b5e8953f7c7d.png" class="list_top_img_min"></el-image>
            <div class="typeList" v-if="typeSeen">
                <div style="width: 20%"></div>
                <div @click="newList(item.id, item.name)" v-for="(item, index) in typeList" :key='index' class="typeItem" :style="params.type == item.id?'color: #007cc7;':''">{{item.name}}</div>
            </div>
            <div class="list_title">{{listTitle}}</div>
            <div class="list">
                <div class="list_item" v-for="(item, index) in articleList" :key='index' @click="details(item.id)">
                    <div class="list_item_title">{{item.title}}</div>
                    <div class="list_item_time">{{newTime(item.createDate)}}</div>
                </div>
                <el-pagination
                        style="margin-top: 20px;"
                        background
                        layout="total, prev, pager, next"
                        :total="params.total"
                        :current-page="params.page"
                        :page-size="params.limit"
                        @current-change="pageChangeHandle">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data () {
            return {
                listTitle: "",
                typeSeen: false,
                typeList: [
                    {
                        id: '7',
                        name: '长仲动态'
                    },
                    {
                        id: '8',
                        name: '重要发文'
                    },
                    {
                        id: '9',
                        name: '通知公告'
                    },
                    {
                        id: '10',
                        name: '法律园地'
                    },
                    {
                        id: '11',
                        name: '党建文化'
                    },
                    {
                        id: '12',
                        name: '案例分析'
                    },
                    {
                        id: '13',
                        name: '仲裁员在线培训'
                    },
                ],
                params: {
                    page: 1,
                    limit: 10,
                    type: '',
                    orderField: 'create_date',
                    order: "desc",
                    total: 0
                },
                articleList: []
            }
        },

        components: {

        },

        watch: {

        },

        mounted() {
            var type = this.$route.query.type
            this.params.type = type
            if (type == '6') {
                this.listTitle = "公告送达"
                this.typeSeen = false
            }
            else {
                this.typeSeen = true
                for (var i in this.typeList) {
                    if (type == this.typeList[i].id) {
                        this.listTitle = this.typeList[i].name
                    }
                }
            }
            this.getArticleList();
        },

        methods: {
            newTime(time) {
                return time.substring(0,10)
            },

            //获取新闻列表
            getArticleList() {
                this.$axios.get(this.$httpUrl + '/CczcArticle/cczcarticle/page',{
                    params: this.params
                }).then(res => {
                    if (res.data.code != 0) {
                        return this.$message.error(res.data.data.msg);
                    }
                    this.articleList = res.data.data.list
                    this.params.total = res.data.data.total
                })
            },

            pageChangeHandle (val) {
                this.params.page = val
                this.getArticleList()
            },

            details(id){
                this.$router.push({
                    path: '/details',
                    query: {
                        dataId: id,
                    }
                })
            },

            newList(type, title) {
                this.listTitle =title
                this.params.type = type
                this.params.page = 1
                this.getArticleList()
            }
        }
    }
</script>

<style scoped>
    .listAll {
        position: relative;
        text-align: center;
    }

    .list_top_img {
        margin-top: 0px;
        margin-left: 0px;
        width: 100%;
        height: auto;
    }

    .list_top_img_min{
        position: absolute;
        width: 25%;
        height: auto;
        top: 200px;
        left: 37.5%;
    }

    .list_title {
        margin-top: 50px;
        text-align: center;
        font-size: 30px;
        font-weight: 700;
    }

    .typeList {
        display: flex;
        width: 100%;
        height: 80px;
        background-color: white;
        line-height: 80px;
        font-size: 14px;
        font-weight: 700;
    }

    .typeItem {
        margin-right: 20px;
        text-align: left;
        cursor: pointer;
    }

    .list {
        margin-top: 50px;
        width: 100%;
        margin-bottom: 50px;
    }

    .list_item {
        position: relative;
        width: 60%;
        left: 20%;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
    }

    .list_item:hover .list_item_title {
        color: #007cc7;
    }

    .list_item_title {
        position: absolute;
        width: 80%;
        font-size: 14px;
        font-weight: 700;
        left: 0px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
    }

    .list_item_time {
        position: absolute;
        width: 20%;
        font-size: 14px;
        font-weight: 700;
        right: 0px;
        text-align: right;
    }
</style>
